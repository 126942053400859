<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Update" }} Learning Centre Admin</h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- NAME -->
        <vs-input
          label="Full Name"
          v-model="fullName"
          class="w-full"
          name="fullName"
          v-validate="'required|alpha_spaces|max:150'"
          data-vv-as="Full Name" />
        <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first('fullName') }}</span>

        <vs-input
          class="w-full mt-5"
          name="email"
          v-validate="'required|email'"
          data-vv-as="Email"
          label="Email"
          v-model="email"/>
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <vs-input
          label="Contact Number"
          v-model="mobileNumber"
          class="mt-5 w-full"
          name="mobileNumber"
          v-validate="'required|numeric'"
        data-vv-as="Contact Number"/>
        <span class="text-danger text-sm" v-show="errors.has('mobileNumber')">{{ errors.first('mobileNumber') }}</span>

        <div class="vx-col w-full mt-5">
          <label class="block mb-2">Country</label>
          <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="countryFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col w-full mt-5">
          <label class="block mb-2">State</label>
          <v-select :options="stateOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="stateFilter" class="mb-4 md:mb-0" />
        </div>

        <div class="w-full mt-5">
          <label class="block mb-2">Account Status</label>
          <vs-switch v-model="status"/>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        userData: {
          fullName: "",
          email: "",
          mobileNumber: "",
          state: "",
          status: false,
        },
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        fullName: "",
        email: "",
        mobileNumber: "",
        status: false,
        countryOptions: [],
        stateOptions: [],
        countryFilter: {label: 'All', value: 'all'},
        stateFilter: {label: 'All', value: 'all'},
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
      centerId: {
        type: String,
        default: ""
      }
    },
    components: {
      vSelect,
      VuePerfectScrollbar,
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.centerId = this.centerId
          this.userData.fullName = this.data.fullName
          this.userData.email = this.data.email
          this.userData.mobileNumber = this.data.mobileNumber
          this.userData.status = false
          this.initValues()
        }
      },
      countryFilter(obj) {
        this.stateOptions = obj.states;
      },
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any() &&
          this.fullName != "" &&
          this.email != "" &&
          this.mobileNumber != "" &&
          this.countryFilter.value != "all" &&
          this.stateFilter.value != "all"
      },
    },
    methods: {
      ...mapActions("center", [
        "addCenterAdmin",
        "getAllCountries"
      ]),
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      initValues() {
        if (this.data._id) return
        this.fullName = ""
        this.email = ""
        this.mobileNumber = ""
        this.status = false
        this.countryFilter = {label: 'All', value: 'all'};
        this.stateFilter = {label: 'All', value: 'all'};
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading()
            this.userData.fullName = this.fullName;
            this.userData.email = this.email;
            this.userData.mobileNumber = this.mobileNumber;
            this.userData.status = this.status;
            this.userData.state =  this.stateFilter.value;
            let info = {
              details: this.userData,
              centerId: this.centerId
            };
            this.addCenterAdmin(info)
              .then((res) => {
                if (res.status === 200) {
                  this.$vs.loading.close()
                  this.$emit('refreshData', true)
                  this.showMessage("Success", "Centre Admin added successfully.", "success");
                }
              })
              .catch(err => {
                this.$vs.loading.close()
                if (err.response.status === 422) {
                  this.showMessage("Failed", err.response.data.message, "danger");
                }
              })
          }
        })
        this.$emit('closeSidebar')
        //this.initValues()
      }
    },
    created() {
      this.getAllCountries()
        .then((res) => {
          this.countryOptions = this.$store.state.center.countryOptions;
        })
        .catch(err => {
          //console.error(err)
        })
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
