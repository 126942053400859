<template>

  <div id="page-user-list">
    <LearningCentreSideBar
      :isSidebarActive="addCenterSidebar"
      @closeSidebar="toggleCenterDataSidebar"
      :data="centerSidebarData"
      :centerId="centerId"
      @refreshData="refreshData"
    />
    <AddAdminSideBar
      :isSidebarActive="addAdminSidebar"
      @closeSidebar="toggleAdminSidebar"
      :data="adminSidebarData"
      :centerId="centerId"
      @refreshData="refreshData"
    />
    <vx-card class="mb-base">
      <div class="flex mb-5">
        <div>
          <h2>{{ centerDetails.name }}</h2>
        </div>
        <div class="ml-auto">
          <vs-button @click="editCenter(centerId)">Edit</vs-button>
          <!--          <vs-button @click="deleteCenterData(centerId)" class="ml-4" color="danger">Delete</vs-button>-->
          <vs-button @click="statusToggle(centerId)" class="ml-4"
                     :color="!centerDetails.isActive ? 'success' : 'danger' ">
            {{ !centerDetails.isActive ? 'Active' : 'InActive' }}
          </vs-button>
        </div>
      </div>
      <vs-row class="admin-detail-wrap">
        <vs-col class="vs-con-loading__container" vs-sm="12" vs-lg="4">
          <div class="w-full h-full p-5 center-logo-wrap">
            <img
              :src="centerDetails.logo"
            />
          </div>
        </vs-col>
        <vs-col vs-sm="12" vs-lg="8">
          <div class="w-full p-5">
            <label class="font-bold">Centre Name:</label>
            <p>{{ centerDetails.name }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Street Address:</label>
            <p>{{ centerDetails.address ? centerDetails.address.displayAddress : '' }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Phone Number:</label>
            <p>{{ centerDetails.phoneNumber }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Email:</label>
            <p>{{ centerDetails.email }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Town/City:</label>
            <p>{{ centerDetails.address ? centerDetails.address.suburb : '' }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">State:</label>
            <p>{{ centerDetails.address ? centerDetails.address.state : '' }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Postcode:</label>
            <p>{{ centerDetails.address ? centerDetails.address.postCode : '' }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Country:</label>
            <p>{{ centerDetails.country ? centerDetails.country.name : '' }}</p>
          </div>
          <div class="w-full p-5">
            <label class="font-bold">Status:</label>
            <vs-button class="ml-4" :color="centerDetails.isActive ? 'success' : 'danger'">
              {{ centerDetails.isActive ? 'Active' : 'InActive' }}
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card class="mb-base">
      <h3 class="mb-5">Assessments Subjects</h3>
      <p class="font-semibold mb-5">Personhood 360 Standard
        <router-link :to="{ name: 'learning-center-presets-assessment-subject'}" class="underline ml-5">View
        </router-link>
      </p>
    </vx-card>
    <vx-card class="mb-base">
      <div class="flex justify-between items-center mb-5">
        <h3>Centre Administration Team</h3>
        <vs-button class="mr-4" @click="addAdmin(centerId)">Add Admin</vs-button>
      </div>
      <div class="mb-5">
        <vs-table max-items="5" pagination :data="centerAdmin">
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Role</vs-th>
            <vs-th>Username</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.fullName">
                {{ tr.fullName | capitalize }}
              </vs-td>
              <vs-td :data="tr.role">
                <p>Admin</p>
              </vs-td>
              <vs-td :data="tr.email">
                {{ tr.email }}
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <router-link :to="{ name: 'learning-centre-admin-view', params: {id: tr._id, centerId: centerId } }"
                             class="underline">View
                </router-link>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <vx-card class="mb-base">
      <div class="flex justify-between items-center mb-5">
        <h3>Centre Details</h3>
      </div>
      <div class="mb-5">
        <vs-table :data="centerListDetails">
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th class="justify-center">Number</vs-th>
            <vs-th class="action-col text-center">Action</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">
                {{ tr.name | capitalize }}
              </vs-td>
              <vs-td :data="tr.number" class="text-center">
                {{ tr.number }}
              </vs-td>
              <vs-td class="whitespace-no-wrap text-center">
                <router-link :to="{ name: 'learning-centre-view-list', params: {id: centerId, type: tr.type}}"
                             class="underline">View
                </router-link>
                <!--                <router-link :to="{}" class="underline">View</router-link>-->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import {mapActions} from "vuex";
import LearningCentreSideBar from "./learningCentreSidebar";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import AddAdminSideBar from "./addAdminSideBar";

export default {
  components: {
    vSelect,
    LearningCentreSideBar,
    AddAdminSideBar
  },
  data() {
    return {
      addNewDataSidebar: false,
      addCenterSidebar: false,
      addAdminSidebar: false,
      sidebarData: {},
      centerSidebarData: {},
      adminSidebarData: {},
      centerDetails: {},
      admins: [],
      centerAdmin: [],
      centerListDetails: {},
      centerId: ''
    }
  },
  watch: {},
  computed: {},
  methods: {
    ...mapActions("center", [
      "getCenterById",
      "getCenterAdminList",
      "deleteCenter",
      "getCenterDetailList",
      "updateCenterStatus"
    ]),
    getCenterDetails(id) {
      this.$vs.loading();
      this.getCenterById(id)
        .then((res) => {
          if (res.status === 200) {
            this.centerDetails = res.data.data;
          }
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          //console.error(err)
        })
    },
    getAdminList(id) {
      this.getCenterAdminList(id)
        .then((res) => {
          this.centerAdmin = res.data.data;
        })
    },
    getCenterList(id) {
      this.getCenterDetailList(id)
        .then((res) => {
          this.centerListDetails = res.data.data;
        })
    },
    addAdmin() {
      this.adminSidebarData = {};
      this.toggleAdminSidebar(true)
    },
    editCenter(id) {
      this.$router.push({name: 'learning-centre-edit', params: {id: id}});
    },
    async statusToggle(centerId) {
      Swal.fire({
        title: 'Are you sure you want to update status of this centre?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update it!'
      }).then((result) => {
        if (result.value) {
          this.updateStatus(centerId)
        }
      })

    },
    async updateStatus(centerId){
      await this.updateCenterStatus(centerId).then((response) => {
        this.showMessage('Success', 'Status updated successfully.', 'success')
        this.getCenterDetails(centerId);
      }).catch((error) => {
        this.showMessage('Error', error.message, 'danger')
        console.log(error);
      })
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    deleteCenterData(id) {
      Swal.fire({
        title: 'Are you sure you want to delete this centre?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.acceptDeleteAlert(id)
        }
      })
    },
    acceptDeleteAlert(id) {
      this.$vs.loading()
      this.deleteCenter(id)
        .then((res) => {
          if (res.status === 200) {
            this.centers = this.$store.state.center.centersOfGroup;
            this.$vs.loading.close()
            this.showMessage("Success", "Centre deleted successfully.", "success");
          }
          this.$router.push({name: "learning-centre-list"});
        })
        .catch(err => {
          this.$vs.loading.close()
          // console.error(err)
        })
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    toggleCenterDataSidebar(val = false) {
      this.addCenterSidebar = val
    },
    toggleAdminSidebar(val = false) {
      this.addAdminSidebar = val
    },
    updateData(data) {
      this.centerDetails.name = data.companyName;
      this.centerDetails.fullName = data.fullName;
      this.centerDetails.phoneNumber = data.contactNumber;
      this.centerDetails.email = data.email;
      this.centerDetails.country.name = data.country;
      this.centerDetails.address.state = data.state;
      this.centerDetails.logo = data.logo
    },
    refreshData(val = false) {
      if (val) {
        let id = this.$route.params.id;
        this.getCenterDetails(id);
        this.getAdminList(id);
        this.getCenterList(id);
      }
    },
  },
  mounted() {
  },
  created() {
    this.centerId = this.$route.params.id;
    let id = this.$route.params.id;
    this.getCenterDetails(id);
    this.getAdminList(id);
    this.getCenterList(id);
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.deleteBtn {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}

.admin-detail-wrap {
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;

  .center-logo-wrap {
    img {
      width: 100%;
      height: 240px;
      object-fit: scale-down;
    }
  }

  .w-full {
    border-bottom: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;

    label {
      position: relative;
      margin-right: 20px;

      &:after {
        content: ':';
        font-weight: 700;
        position: absolute;
        top: -1px;
        right: 0;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      label {
        width: 250px;
      }
    }
  }
}
</style>
